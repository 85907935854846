<template>
<div> 

<p class="text-h5 text-center font-weight-bold">
      {{allProjectDetails.job_number}}
</p>

<p><strong>Description:</strong> {{ allProjectDetails.description}}</p>
<p><strong>Address:</strong> {{ allProjectDetails.address_1}} - {{ allProjectDetails.city}}, {{ allProjectDetails.state}} {{allProjectDetails.zip_code}}</p>
<!-- <p><strong>Start Date:</strong> {{ allProjectDetails.actual_start_date}}</p> -->
<p><strong>Current Phase:</strong> {{ allProjectDetails.current_phase }}</p>

<!-- <p><strong>Dry-in Dates:</strong> Start: {{ allProjectDetails.dryin_dates.start_date }}, End: {{ allProjectDetails.dryin_dates.end_date }}</p>
<p><strong>Lay Tile Dates:</strong> Start: {{ allProjectDetails.laytile_dates.start_date }}, End: {{ allProjectDetails.laytile_dates.end_date }}</p>
<p><strong>Availability Dates:</strong> Start: {{ allProjectDetails.availability_dates.start_date }}, End: {{ allProjectDetails.availability_dates.end_date }}</p> -->


<!-- <p><strong>Current Phase by Date: </strong>{{ currentPhase.phase_id.phase_name }} - {{ currentPhase.create_date }}</p> -->
<!-- <p v-if="projectPhases.length>0"><strong>Current Phase: </strong>{{ currentPhaseById.phase_id.phase_name }}</p> -->
<!-- {{allProjectPhases}} -->
<p>
  <v-expansion-panels>
    
    <v-expansion-panel>
      <v-expansion-panel-header>
        Date Details
      </v-expansion-panel-header>
      <v-expansion-panel-content>

        <table class="text-caption text-center pa-1 ma-1" width="100%">
            <thead>
              <tr>
                <th>Phase</th>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>
              <tr>
                <td>Dry-in</td>
                <td>
                  <date-picker
                    :date="dates['10'].phase_date"
                    :info="{phase_id: 10, ...latestPhases['10'], update: 'phase_date'}"
                    @change="apiUpdateDate"
                  />
                </td>
                <td>
                  <date-picker
                    :date="dates['10'].phase_date_end"
                    :info="{phase_id: 10, ...latestPhases['10'], update: 'phase_date_end'}"
                    @change="apiUpdateDate"
                  />
                </td>
              </tr>
              <tr>
                <td>Lay Tile</td>
                <td>
                  <date-picker
                    :date="dates['17'].phase_date"
                    :info="{phase_id: 17, ...latestPhases['17'], update: 'phase_date'}"
                    @change="apiUpdateDate"
                  />
                </td>
                <td>
                  <date-picker
                    :date="dates['17'].phase_date_end"
                    :info="{phase_id: 17, ...latestPhases['17'], update: 'phase_date_end'}"
                    @change="apiUpdateDate"
                  />  
                </td>
              </tr>
              <tr>
                <td>Availability</td>
                <td>
                  <date-picker
                    :date="dates['27'].phase_date"
                    :info="{phase_id: 27, ...latestPhases['27'], update: 'phase_date'}"
                    @change="apiUpdateDate"
                  />  
                </td>
                <td>
                  <date-picker
                    :date="dates['27'].phase_date_end"
                    :info="{phase_id: 27, ...latestPhases['27'], update: 'phase_date_end'}"
                    @change="apiUpdateDate"
                  />
                </td>
              </tr>
        </table>
        
        </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header>
        Project Details
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container>
          <div>         
            <v-banner color="amber lighten-4">
              <strong>Project Options:</strong> {{allProjectDetails.options}}
            </v-banner>
          </div> 
          <br>
          <v-row>
            <v-col cols="6">
              <p>
              <strong>Builder:</strong> {{ allProjectDetails.builder}} <br>
              <strong>Subdivision:</strong> {{ allProjectDetails.sub_division}} <br>
              <strong>Plan:</strong> {{ allProjectDetails.plan_number}} <br>
              <strong>Elevation:</strong> {{ allProjectDetails.elevation}} <br>
              <strong>High/Low Pitch:</strong> {{ allProjectDetails.high_low_pitch}} <br>
              <strong>Manufacturer:</strong> {{ allProjectDetails.manufacturer}} <br>
              <strong>Tile #:</strong> {{ allProjectDetails.tile_number}} <br>
              <strong>Field Count:</strong> {{ allProjectDetails.tile_field_count}} <br>
              <strong>Mansard Count:</strong> {{ allProjectDetails.mansard_count}} <br>
              <strong>Ridge Count:</strong> {{ allProjectDetails.hip_ridge_count}} <br>
              <strong>Rake Count:</strong> {{ allProjectDetails.rake_count}} <br>
              <strong>Tile Squares:</strong> {{ allProjectDetails.tile_squares}} <br>
              </p>
            </v-col>
            <v-col cols="6">
              <p>
              <strong>2 Ply:</strong> {{ allProjectDetails.ply_felt_2}} <br>
              <strong>Super:</strong> {{ allProjectDetails.superintendent}} <br>
              <strong>Lot:</strong> {{ allProjectDetails.lot_number}} <br>
              <strong>DI PO:</strong> {{ allProjectDetails.di_po_number}} <br>
              <strong>DI Supplier:</strong> {{ allProjectDetails.di_supplier}} <br>
              <strong>Tile PO:</strong> {{ allProjectDetails.tile_po_number}} <br>
              <strong>Tile Supplier:</strong> {{ allProjectDetails.tile_supplier}} <br>
              <strong>Load PO:</strong> {{ allProjectDetails.load_po_number}} <br>
              <strong>Load Vendor:</strong> {{ allProjectDetails.load_vendor}} <br>
              <strong>Sales Order #:</strong> {{ allProjectDetails.yard_order_number}} <br>
              <strong>Supplier:</strong> {{ allProjectDetails.supplier}} <br>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</p>

<v-container >
  <v-row>
    <v-col cols="4">
      <v-dialog v-model="dialogDryIn" :max-width="height">
        <template v-slot:activator="{ on, attrs }">
        <v-btn
        class="pa-6 text-center"
        width="100%"
        height="100%"
        :disabled="btnDryInReleaseDisabled"
        v-bind="attrs"
        v-on="on"
        >
        DRY IN <br> RELEASE
        </v-btn>
        </template>
        <confirmation-modal
        :modalTitle="'Dry In Release'"
        :modalText="'Please confirm release of dry in materials'"
        :isDashboard="true"
        :isComplete="false"
        :phaseName="'Release DI Material'" 
        :phaseId="6" 
        :userId="allUserInfo.drc_user_id"
        :jobNumber="jobNumber"
        :jobDetail="allProjectDetails"
        :userDetail="allUserInfo"
        
        @close-dialog="closeDialog"
        @confirm-dryin-clicked="confirmDryinClicked"
        ></confirmation-modal>
      </v-dialog>
    </v-col>
    <v-col cols="4">
      <v-dialog
        v-model="dialogTile"
        :max-width="height"
        >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
        class="pa-6 text-center"
        width="100%"
        height="100%"
        :disabled="btnTileReleaseDisabled"
        v-bind="attrs"
        v-on="on"
        >
        TILE <br> RELEASE
        </v-btn>
        </template>
        <confirmation-modal
        :modalTitle="'Tile Release'"
        :modalText="'Please confirm release of tile'"
        :isDashboard="true"
        :isComplete="false"
        :phaseName="'Release Tile'" 
        :phaseId="8" 
        :userId="allUserInfo.drc_user_id"
        :jobNumber="jobNumber"
        :jobDetail="allProjectDetails"
        :userDetail="allUserInfo"
        
        @close-dialog="closeDialog"
        ></confirmation-modal>
      </v-dialog>
    </v-col>
    <v-col cols="4">
      <v-dialog
        v-model="dialogLoaded"
        :max-width="height"
        >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
        class="pa-6 text-center "
        width="100%"
        height="100%"
        :disabled="btnLoadedDisabled"
        v-bind="attrs"
        v-on="on"
        >
        LOADED
        </v-btn>
        </template>
        <confirmation-modal
        :modalTitle="'Loaded'"
        :modalText="'Please confirm roof is loaded'"
        :isDashboard="true"
        :isComplete="true"
        :phaseName="'Load-Schedule'" 
        :phaseId="14" 
        :userId="allUserInfo.drc_user_id"
        :jobNumber="jobNumber"
        :jobDetail="allProjectDetails"
        :userDetail="allUserInfo"
        
        @close-dialog="closeDialog"
        ></confirmation-modal>
      </v-dialog>            
    </v-col>
</v-row>
<v-row>
    <v-col>
        <v-dialog
        v-model="dialog1"
        :max-width="height"
        
        >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="pa-6 text-center"
            width="100%"
            height="100%"                
            v-bind="attrs"
            v-on="on"
            >
            STATUS
        </v-btn>
        </template>
              <v-card>
                <v-card-title class="text-h5">
                STATUS
                </v-card-title>
                <v-container >

                    <v-row
                    v-for="(phase, index) in allPhases"
                    
                    :key="index"
                    :id="phase.id"
                    
                    dense
                    >
                    
                        <v-col 
                        v-if="phase.is_quick_complete">
                          
                            <!-- StatusButton Component -->
                            <status-button 
                              
                            
                              :key="phase.id" 
                              :phaseName="phase.phase_name" 
                              :phases="projectPhases"
                              :phaseId="phase.id" 
                              :userId="allUserInfo.drc_user_id"
                              :jobNumber="jobNumber"
                              :btnDisabled="phase.disabled"
                              @close-dialog="closeDialog"
                              
                              >
                            </status-button>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </v-col>
</v-row>
<v-row>
    <v-col>
        <v-dialog
        v-model="dialogDryInComp"
        :max-width="height"
        >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="pa-6 text-center"
            width="100%"
            height="100%"                
            v-bind="attrs"
            v-on="on"
            color="error"
            :disabled="btnDryInDisabled"
            >
            DRY IN COMPLETE
        </v-btn>
        </template>
                    <v-card>
                    <v-card-title class="text-h5">
                    Dry In Completion <br>
                    Dry-In Quality Inspection / Inspeccion De Calidad
                    </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                      <dry-in-form
                      :userId="allUserInfo.drc_user_id"
                      :jobNumber="jobNumber"
                      :jobDetail="allProjectDetails"
                      :userDetail="allUserInfo"
                      
                      @close-dialog="closeDialog"
                      ></dry-in-form>
                    </v-card-actions>
                </v-card>
            
            </v-dialog>
    </v-col>
</v-row>
<v-row>
    <v-col>
        <v-dialog
        v-model="dialogTileComp"
        :max-width="height"
        >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="pa-6 text-center"
            width="100%"
            height="100%"                
            v-bind="attrs"
            v-on="on"
            color="error"
            :disabled="btnTileLayDisabled"
            >
            TILE LAY COMPLETE
        </v-btn>
        </template>
                    <v-card>
                    <v-card-title class="text-h5">
                    Tile Lay Completion <br>
                    Tile Lay Quality Inspection / Inspeccion De Calidad
                    </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                      <tile-lay-form
                      :userId="allUserInfo.drc_user_id"
                      :jobNumber="jobNumber"
                      :jobDetail="allProjectDetails"
                      :userDetail="allUserInfo"
                      @close-dialog="closeDialog"
                      ></tile-lay-form>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-col>
  </v-row>
</v-container>
</div> 
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import axios from 'axios'
import StatusButton from '../components/StatusButton.vue';
import ConfirmationModal from '../components/ConfirmationModal.vue';
import DryInForm from '../components/DryInForm.vue';
import TileLayForm from '../components/TileLayForm.vue'

export default {
    name: 'ProjectStatus',
    components: {
      StatusButton,
      ConfirmationModal,
      DryInForm,
      TileLayForm,
      'date-picker': require('@/components/DatePicker.vue').default
      },
    data() {
        return {
          phases: [],
          projectPhaseList: [],
          dialog1: false,
          dialog2: false,
          dialogDryIn: false,
          dialogDryInComp: false,
          dialogTileComp: false,
          dialogTile: false,
          dialogLoaded: false,
          jobNumber: this.$route.params.id,
          dryinConfirmClicked: false,

        }
    },

    created() {
        this.fetchProjectDetails(this.$route.params.id)
        // this.getPhases()
        this.fetchCurrentUser()

        this.fetchPhases()
        // this.fetchProjectPhases(this.$route.params.id)
        // this.fetchProjectPhasesList()
    },

    computed: {
      ...mapGetters(['allProjectDetails', 'allUserInfo', 'allPhases', 'allProjectPhases', 'allProjectPhasesList']),

      height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "100%"
          case 'sm': return "50%"
          case 'md': return "30%"
          case 'lg': return "30%"
          case 'xl': return "30%"
        }
      },
      
      // currentPhase() {
      //     if (this.projectPhaseList.length > 0){
      //     const result = this.projectPhaseList.reduce((r, o) => o.create_date > r.create_date ? o : r);
      //     return(result)}
          
      // },

      // currentPhaseById() {
      //     // const result = this.projectPhaseList.reduce((r, o) => o.phase_id.id > r.phase_id.id ? o : r);
      //       const phaseArray = [1, 2, 3, 4, 5, 7, 9, 11]
      //       const result = this.projectPhaseList.reduce()
      //     // const result = this.projectPhaseList.filter(value => phaseArray.includes(value))
      //     // .filter((value, index, self) => self.indexOf(value) === index)
      //     console.log(result)

      //     return(1)
      // },
      projectPhases() {
        // const phasesList = []
        const currentPhases = []
        this.allProjectDetails.phases?.forEach((item) => {
          currentPhases.push(item.phase_id)
        })
        // console.log(currentPhases)
        return(currentPhases)
        // console.log(currentPhases)

        // this.allPhases.forEach((item) => {
        //   // console.log(item.id)
        //     if (currentPhases.indexOf(item.id) != -1){
        //       phasesList.push({
        //       "id": item.id, 
        //       "disabled": true, 
        //       "phase_name": item.phase_name, 
        //       "is_quick_complete": item.is_quick_complete
        //       })
        //     } else {
        //       phasesList.push({
        //       "id": item.id, 
        //       "disabled": false, 
        //       "phase_name": item.phase_name, 
        //       "is_quick_complete": item.is_quick_complete
        //       })
        //     }
        // })
        // // console.log(phasesList)
      },
      // testlist() {
      //   var phaselist = []
      //   var phaselist2 = []

      //   this.projectPhaseList.forEach((item) => {
      //     phaselist.push(item.phase_id.id)
      //   })
        
      //   var phaselist = [...new Set(phaselist)]
      //   // console.log(phaselist)

      //   this.phases.forEach((item) => {

      //     if (phaselist.indexOf(item.id) != -1) {
      //       phaselist2.push({
      //         "id": item.id, 
      //         "disabled": true, 
      //         "phase_name": item.phase_name, 
      //         "is_quick_complete": item.is_quick_complete
      //         })
      //     } else {
      //       phaselist2.push({
      //         "id": item.id, 
      //         "disabled": false, 
      //         "phase_name": item.phase_name, 
      //         "is_quick_complete": item.is_quick_complete
      //         })
      //       }
      //   }
      //   )
      // return(phaselist2)
      // },

      btnDryInDisabled() {
        //   if (this.projectPhases.indexOf(10) != -1) {
        //   return(true) 
        // } else { return(false) }
        return(true)
      },

      btnTileLayDisabled() {
        //   if (this.projectPhases.indexOf(8) != -1) {
        //   return(true) 
        // } else { return(false) }
        return(true)
      },

      btnLoadedDisabled() {
          if (this.projectPhases.indexOf(14) != -1) {
          return(true) 
        } else { return(false) }
      },

      btnDryInReleaseDisabled() {
        if (this.projectPhases.indexOf(6) != -1 || this.dryinConfirmClicked) {
          return(true) 
        } else { return(false) }
      },

      btnTileReleaseDisabled() {
          if (this.projectPhases.indexOf(8) != -1) {
          return(true) 
        } else { return(false) }
      },
      dates () {
        const null_dates = {phase_date: null, phase_end_date: null}
        const defaults = {'10': null_dates,'17': null_dates,'27': null_dates}
        return {...defaults, ...this.allProjectDetails.dates}
      },
      latestPhases () {
        const phaseDefaults = {
          job_number: null,
          phase_id: null,
          user_id: null,
          phase_date: null,
          crew: null,
          phase_notes: null,
          is_complete: null,
          is_dashboard: null,
          create_date: null,
          date_notes: null,
          phase_date_end: null
        }
        const defaults = {'10':phaseDefaults, '17':phaseDefaults, '27':phaseDefaults}
        return {...defaults, ...this.allProjectDetails.latest_phases}
      }

    },
    methods: {
      ...mapActions(['fetchProjectDetails', 'fetchCurrentUser', 'fetchPhases', 'fetchProjectPhases', 'fetchProjectPhasesList','showSnackbar']),
        testMethod() {
          console.log(this.projectPhases)
        },
        getPhases() {
            axios
                .get('/api/v1/project/phases/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                            this.phases.push(response.data[i])
                }})
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        closeDialog(id) {
            this.dialog1 = false
            this.dialog2 = false
            this.dialogDryIn = false
            this.dialogTile = false
            this.dialogLoaded = false
            this.dialogDryInComp = false
            this.dialogTileComp = false
            this.fetchProjectDetails(this.$route.params.id)
        },
        confirmDryinClicked() {
          this.dryinConfirmClicked = true
        },
        apiUpdateDate (args) {
          const [new_date,info] = args

          // info is the previous most recent row from the job_phase table
          // which any existing fields need to be rolled forward, but we
          // can only update the date if we know the phase_id and the date
          // field to be updated. info.update should be 'phase_date' or
          //'phase_date_end' to indicate which of the date fields is being
          // updated from the date picker
          if (info.phase_id && info.update) {

            let now = new Date().toISOString()

            // Create the form date from the previous row in the job_phase table
            const form_data = {
              job_number: this.allProjectDetails.job_number,
              phase_id: info.phase_id,
              user_id: this.allUserInfo.drc_user_id,
              phase_date: info.phase_date || null,
              crew: info.crew || '',
              phase_notes: info.phase_notes || '',
              is_complete: info.is_complete || false,
              is_dashboard: info.is_dashboard || false,
              create_date: now,
              date_notes: now.slice(5,7)+'-'+now.slice(8,10)+'-'+now.slice(2,4)+'-'+this.allUserInfo.username,
              phase_date_end: info.phase_date_end || null
            }
            // let old_date = form_data[info.update]

            // Update the form data field with the new date
            form_data[info.update] = new_date

            const url = 'api/v1/project/job-phase/'
            const thiscopy = this   // required to make showSnackbar in the .catch scope below
            axios
              .post(url, form_data)
              .then(function(result) {
                console.log(result.status)
              })
              .catch(function(error) {
                console.log(error)
                // Initial error shows up here, ex. if API is unreachable, but
                // subsequent date change attempts will hit the error state in
                // the else block below (info.phase_id && info.update won't be
                // set on subsequent date selections)
                thiscopy.showSnackbar({message:'Error: Date did not update',color:'error'})
              })
              this.fetchProjectDetails(this.$route.params.id)
            }
            else {
              // 2nd time API is unreachable and a date change is attempted we
              // will land here
              console.log('Error')
              this.showSnackbar({message:'Error: Date did not update', color:'error'})
            }
        }
    }
}

</script>