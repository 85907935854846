import Vue from 'vue'
import Vuex from 'vuex'
import projects from './modules/projects'

//Load Vuex
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      username: ''
    },
    isAuthenticated: false,
    token: '',
    snackbar: { visible: false, message: '', color: ''}
  },
  getters: {
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
    },
    setSnackbar (state, snackbar) {
      state.snackbar = snackbar
    }
  },
  actions: {
    showSnackbar ({ commit }, args) {
      const default_vals = {visible:true, message:'', color:'primary'}
      commit('setSnackbar', {...default_vals, ...args})
    }
  },
  modules: {
    projects
  }
})
