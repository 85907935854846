<template>
    <v-btn                       
    class="pa-6 ma-0 text-center"
    width="100%"
    height="100%"
    @click="closeDialog"
    color="blue darken-4"
    :disabled="btnDisabledCom"
    >
        <span class="white--text">{{phaseName}}</span>
    </v-btn>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
// import axios from 'axios'
export default {
    name: "StatusButton",
    emits: ['close-dialog'],
    props:[
        'phaseName', 
        'phaseId',
        'jobNumber',
        'userId',
        'btnDisabled',
        'phases'
        ],
    data() {
        return{}
    },
    computed: {
      //  ...mapGetters(['allProjectDetails',]),

       btnDisabledCom() {
        if (this.phases.indexOf(this.phaseId) != -1) {
          return(true) 
        } else { return(false) }
       },
    },
    methods:{
      ...mapActions(['postProjectPhase']),

        closeDialog() {
          const date = new Date().toISOString()
          const formData = {
            job_number: this.jobNumber,
            phase_id: this.phaseId,
            user_id: this.userId,
            phase_date: date,
            phase_notes: '*auto mobile* ' + this.phaseName,
            is_complete: true,
            is_dashboard: true,
            create_date: date,
            crew: '',
            date_notes: ''
          }
          // console.log(formData)
          // axios
          //   .post('/api/v1/project/job-phase/', formData)
          //   .then(response => {
          //     console.log(JSON.stringify(response))
          //   })
          //   .catch(error => {
          //     console.log(JSON.stringify(error))
          //   })
          this.postProjectPhase(formData)
        
            // console.log(formData)

          this.$emit('close-dialog', this.phaseId)
          // this.$router.push('/projects')
        }
    }
}
</script>