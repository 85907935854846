<template>
<div>
    <v-radio-group v-model="is_pulte_felt_wall" row>
        PULTE. Is the felt and/or modified run 12” up the wall? <br>
        Esta corrida el felt de 12 pulgadas en la pared?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_pulte_ohagin" row>
        PULTE. Are the Ohagin vents skirted with felt and cut so that the felt can be torn off? <br>
        Estan instalado los Ohagins con un “skirt” de felt y cortado para que se puede sacar?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_pulte_staples_felt" row>
        PULTE. No staples left in felt? <br>
        Hay grapas en el felt?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_metal_work_installed" row>
        Was all metal work installed and sealed properly? <br>
        staba el metal instalado y sellado correctamento?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_basejacks_installed" row>
        Did you install all basejacks - (plumbing, a/c and gas) correctly? <br>
        Instalo todas las Bases/jacks y T-tops correctamente?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_felt_cut_back" row>
        Is all felt cut back 2 inches from gas pipes ? <br>
        Se corto el papel a 2 pulgadas de los tubos de gas ?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_diverters_installed" row>
        Did you install all diverters where needed ? <br>
        Instalo los canales y metalde desvicion del agua endonde se necesitan ?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_ridgeboard_installed" row>
        Is all the ridgeboard installed and wrapped 2 inches onto deck? (los dos lados) <br>
        Estan todos los barrotes instalados y forrados a 2 pulgadas de la horilla? (los 2 lados)
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_ridgeboard_cut_back" row>
        Is the ridge board cut back 17 inches from the hip y 4 inches from the ridge? <br>
        Se cortaron los barrotes a 17 pulgadas de la caderas y 4 pulgadas del ridge?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_splash_guard_installed" row>
        Did you install splash guards at crickets? <br>
        Se instalo el metal detras de los crickets?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_felt_head_nailed" row>
        Was the felt head lap nailed with simplex using an 6 to 8 inch spacing? <br>
        Se clavo el papel con simplex a 6 a 8 pulgadas de uno a otro?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_vents_installed" row>
        Are all O'Hagin vents properly instaled with a 2nd diverter above it? <br>
        Estan las ventilaciones O'Hagins instaladas correctamente con un segundo guia sobre el?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_job_clean" row>
        Did you leave the job clean and free of trash? <br>
        Dejaste el lugar de trabajo limpio y sin basura?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_sticks_installed" row>
        Were the sticks installed to code allowing for a 3 inch headlap? <br>
        Se instalaron las tablas de manera que la teja se encime 3 pulgadas de la otra?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_felt_laps" row>
        Does the felt have a 4 inch head lap and an 18 inch side lap? <br>
        Esta encimado el papel 4 pulgadas de arriba y 18 pulgadas a lo largo?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_edge_metal_ran" row>
        If required was the edge metal ran back to front? <br>
        Si se requiere, instalo el metal de atras hacia el frente?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_edge_metal_smaller" row>
        No metal installed smaller than 3 ft? <br>
        Se usaron pedasos de metal mas chicos de 3 pies?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_edge_metal_joints" row>
        Does all edge metal joints have a 3 inch lap? <br>
        Se ensimaron las horillas del metal por lo menos 3 pulgadas?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_valley_extend" row>
        Does all valley metal corners extend a 1/4 inch past the fascia? <br>
        Las esquinas del metal de valle se dejo que pasara la cara de la pared un 1/4 de pulgada?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_clip_shinners" row>
        Did you clip any and all shinners? <br>
        Se cortaron o se quitaron los clavos que salieron por debajo?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_stucco_felt_ran" row>
        Stucco fascia only, was the felt ran 2 inches past all rake lines? <br>
        En paredes de cemento, se instalo el papel que pasara 2 pulgadas de las lineas de rake?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_nails_j_pan" row>
        There are no nails in the pan portion of the J-pan? <br>
        Hay clavos sobre el metal en la tapaderas J-pan ?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_patio_installed" row>
        Was the patio installed correctly? <br>
        Se instalo el patio correctamente?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_seams_rolled" row>
        Did you roll the seams? And are they tight and secured? <br>
        Presiono con el rodillo las horillas? Estan bien aplastadas y seguras?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_deck_2ply" row>
        If deck is 3.99/12 and below, did you 2 ply the area? <br>
        Si el deck esta a 3.00/12 o menos, puso dos pliegos en todo el area?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_stick_rafter" row>
        If applicable did you secure the first stick into a rafter tail? <br>
        Si aplica, clavo la primera tablita sobre el barrote de abajo?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-textarea
        solo
        name="input-7-4"
        label="NOTES/NOTAS"
        v-model="notes"
    ></v-textarea>
    
        
        
        
        
        <v-btn
        color="red darken-1"
        text
        @click="cancelDialog"
    >
        CANCEL
        </v-btn>
        <v-btn
            color="green darken-1"
            text
            @click="closeModal"
        >
            CONFIRM
        </v-btn>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'DryInForm',
    emits: ['close-dialog'],
    props: [
        'userId',
        'jobNumber',
        'jobDetail',
        'userDetail',
    ],
    data() {
        return {
            is_pulte_felt_wall: null,
            is_pulte_ohagin: null,
            is_pulte_staples_felt: null,
            is_metal_work_installed: null,
            is_barricade_tape_installed: null,
            is_basejacks_installed: null,
            is_felt_cut_back: null,
            is_diverters_installed: null,
            is_ridgeboard_installed: null,
            is_ridgeboard_cut_back: null,
            is_splash_guard_installed: null,
            is_felt_head_nailed: null,
            is_vents_installed: null,
            is_job_clean: null,
            is_sticks_installed: null,
            is_felt_laps: null,
            is_edge_metal_ran: null,
            is_edge_metal_smaller: null,
            is_edge_metal_joints: null,
            is_valley_extend: null,
            is_clip_shinners: null,
            is_stucco_felt_ran: null,
            is_nails_j_pan: null,
            is_patio_installed: null,
            is_seams_rolled: null,
            is_deck_2ply: null,
            is_stick_rafter: null,
            is_extra_material_pallet: null,
            notes: null,
            email_content: null
        }
    },
    methods:{
        cancelDialog() {
            this.$emit('close-dialog')
        },
        closeModal() {
          const date = new Date().toISOString()
          const date2 = new Date().toLocaleDateString()
          const formData = {
                job_number: this.jobNumber,
                user_id: this.userId,
                is_pulte_felt_wall: this.is_pulte_felt_wall,
                is_pulte_ohagin: this.is_pulte_ohagin,
                is_pulte_staples_felt: this.is_pulte_staples_felt,
                is_metal_work_installed: this.is_metal_work_installed,
                is_barricade_tape_installed: this.is_barricade_tape_installed,
                is_basejacks_installed: this.is_basejacks_installed,
                is_felt_cut_back: this.is_felt_cut_back,
                is_diverters_installed: this.is_diverters_installed,
                is_ridgeboard_installed: this.is_ridgeboard_installed,
                is_ridgeboard_cut_back: this.is_ridgeboard_cut_back,
                is_splash_guard_installed: this.is_splash_guard_installed,
                is_felt_head_nailed: this.is_felt_head_nailed,
                is_vents_installed: this.is_vents_installed,
                is_job_clean: this.is_job_clean,
                is_sticks_installed: this.is_sticks_installed,
                is_felt_laps: this.is_felt_laps,
                is_edge_metal_ran: this.is_edge_metal_ran,
                is_edge_metal_smaller: this.is_edge_metal_smaller,
                is_edge_metal_joints: this.is_edge_metal_joints,
                is_valley_extend: this.is_valley_extend,
                is_clip_shinners: this.is_clip_shinners,
                is_stucco_felt_ran: this.is_stucco_felt_ran,
                is_nails_j_pan: this.is_nails_j_pan,
                is_patio_installed: this.is_patio_installed,
                is_seams_rolled: this.is_seams_rolled,
                is_deck_2ply: this.is_deck_2ply,
                is_stick_rafter: this.is_stick_rafter,
                is_extra_material_pallet: this.is_extra_material_pallet,
                notes: this.notes,
                install_date: this.install_date,
                qa_date: this.qa_date,
                super_user_id: this.super_user_id,
                email_content: this.email_content,
                create_date: date,

          }
        //   console.log(formData)
          axios
            .post('/api/v1/project/form-dry-in/', formData)
            .then(response => {
              console.log(JSON.stringify(response))
            })
            .catch(error => {
              console.log(JSON.stringify(error))
            })
            
            const formDataPhase = {
            job_number: this.jobNumber,
            phase_id: 11,
            user_id: this.userId,
            phase_date: date,
            phase_notes: '*auto mobile* Dashboard DI Release Checkbox',
            is_complete: false,
            is_dashboard: true,
            create_date: date,
            crew: '',
            date_notes: date2,
            extra_field: this.jobDetail,
            extra_field_user: this.userDetail, 
            extra_field_date: date2
          }
        //   console.log(formDataPhase)
          axios
            .post('/api/v1/project/job-phase/', formDataPhase)
            .then(response => {
              console.log(JSON.stringify(response))
            })
            .catch(error => {
              console.log(JSON.stringify(error))
            })
        
            // console.log(formData)

            // this.$emit('close-dialog', 6)
            this.$router.push('/projects')
            
        }
    }
}
</script>