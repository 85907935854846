<template>
  <v-app id="tract">

      <!-- <v-navigation-drawer      v-model="drawer"      app      > -->
      <!--  -->
    <!-- </v-navigation-drawer> -->

    <v-app-bar
      app
      color="white"
      flat
    >
      <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->

      <v-app-bar-title left >TRACT</v-app-bar-title>   

      <v-spacer></v-spacer>
      
      <template v-if="$store.state.isAuthenticated">
      <v-btn elevation="2" class="ma-2" text>
        <router-link to="/projects">Projects</router-link>
      </v-btn>
       <v-btn color="error" @click="logout()" >Log out</v-btn>
      </template>

      <!-- <template v-else >
        <div>
        <v-btn elevation="2" text>
          <router-link to="/sign-up"><strong>Sign Up</strong></router-link>
        </v-btn>
        <v-btn elevation="2" text>
          <router-link to="/log-in">Log In</router-link>
        </v-btn>
        </div>
      </template> -->

    </v-app-bar>

 

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col>
            <router-view/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-snackbar v-model="snackbar.visible" :timeout="-1" :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
        OK
        </v-btn>
      </template>
    </v-snackbar>
    
  </v-app>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

  export default {
    beforeCreate() {
      this.$store.commit('initializeStore')

      const token = this.$store.state.token
      if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token


      } else axios.defaults.headers.common['Authorization'] = ""

      if (!this.$store.state.isAuthenticated) {
        this.$router.push('/log-in')
      }

    },
    data: () => ({
      drawer: null,
      links: [
        'Dashboard',
        'Projects',
        'Profile',
      ],
    }),
    methods: {
        logout() {
            axios
                .post("/api/v1/auth/token/logout/")
                .then(response => {

                    axios.defaults.headers.common["Authorization"] = ""

                    localStorage.removeItem("token")

                    this.$store.commit('removeToken')

                    this.$router.push('/log-in')
                })
        }
    },
    computed: {
      ...mapState(["snackbar"])
    }
  }
</script>