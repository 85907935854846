<template>

<div>
        <h1 class="title">My Account</h1>

        <v-btn 
        @click="logout()">Log out</v-btn>

</div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'MyAccount',
    methods: {
        logout() {
            axios
                .post("/api/v1/token/logout/")
                .then(Response => {
                    axios.defaults.headers.common["Authorization"] = ""

                    localStorage.removeItem("token")

                    this.$store.commit('removeToken')

                    this.$router.push('/')
                })
        }
    }
}
</script>