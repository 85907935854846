<template>
<v-card>

  <v-card-title class="text-xs-h6 text-md-h5 text-lg-h4">
    PROJECTS
    
    <v-spacer></v-spacer>
    
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
      @click:append="handleSearch"
      @keyup.native.enter="handleSearch"
    ></v-text-field>
    
    <v-spacer></v-spacer>

    <v-checkbox
      v-model="showCompleted"
      label="Show Completed" 
      dense
    >
    </v-checkbox>
    
    <v-spacer></v-spacer>
    
    <div>
      <v-pagination
        v-model="projectCurrentPage"
        :length="getTotalPages"
        total-visible="5"
        @input="handlePagination"
      ></v-pagination>
    </div>
      
  </v-card-title>

  <v-data-table
      item-key="item.guid"
      :headers="tableHeaders"
      :items="allProjects"
      hide-default-footer
    >
      <template #item.job_number="{ item }">
        <td ><router-link :to="{name: 'ProjectStatus', params: {id: item.job_number }}">{{item.job_number}}</router-link></td>
      </template>
      <!-- 
      <template #item.options="{ item }">
        <td ><router-link :to="{name: 'Project', params: {id: item.job_number }}">{{item.options}}</router-link></td>
      </template> -->        
  </v-data-table>
</v-card>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

const Headers = [
    {
        text: 'Job Number',
        align: 'left',
        sortable: true,
        width: '10%',
        value: 'job_number'
    },
    {
        text: 'Job Name',
        align: 'left',
        sortable: true,
        width: '20%',
        value: 'description'
    },
    {
        text: 'Current Phase',
        align: 'left',
        sortable: true,
        width: '10%',
        value: 'current_phase'
    },
    // {
    //     text: 'Dry-in Start',
    //     align: 'left',
    //     sortable: true,
    //     width: '7%',
    //     value: 'dryin_dates.start_date'
    // },
    // {
    //     text: 'Dry-in End',
    //     align: 'left',
    //     sortable: true,
    //     width: '7%',
    //     value: 'dryin_dates.end_date'
    // },
    {
        text: 'Notes',
        align: 'left',
        sortable: true,
        width: '50%',
        value: 'options'
    }
];

export default {
  name: 'Projects',
  data: () => ({
    tableHeaders: Headers,
    sideMenu: true,
    search: '',
    showCompleted: false,
  }),
    created() {
      this.fetchProjects();
    }
    ,
  computed: {
    ...mapGetters(['allProjects', 'projectCurrentPage', 'getTotalPages'])
  },

  methods: {
    ...mapActions(['fetchProjects', 'fetchProjectPage']),

    handlePagination(value){
      this.fetchProjectPage(value)
      this.fetchProjects([this.search]);
    },

    handleSearch() {
      this.fetchProjects([this.search, this.showCompleted])
    }     
  }
};
</script>

