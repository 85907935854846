<template>

                        <v-card>
                        <v-card-title class="text-h5">
                        {{modalTitle}}
                        </v-card-title>
                        <v-card-text>{{modalText}}</v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red darken-1"
                            text
                            @click="cancelDialog"
                        >
                            CANCEL
                        </v-btn>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="(phaseId == 6) ? (updateJob(), closeModal()) : (closeModal())"
                            
                        >
                            CONFIRM
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                

</template>

<script>
import projects from '@/store/modules/projects'
import axios from 'axios'
export default {
    name: "ConfirmationModal",
    emits: ['close-dialog'],
    props:[
        'phaseName', 
        'phaseId',
        'jobNumber',
        'userId',
        'modalTitle',
        'modalText',
        'isDashboard',
        'isComplete',
        'jobDetail',
        'userDetail'
        ],
    data() {
        return{}
    },
    methods:{
        cancelDialog() {
            this.$emit('close-dialog', this.phaseId)
        },
        updateJob() {
            const formData = {
                job_number: this.jobNumber,
                is_dashboard_release: true
            }
            axios
                .put('/api/v1/project/job/' + this.jobNumber + '/', formData)
                .then(response => {
                    console.log(JSON.stringify(response))
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        closeModal() {
          const date = new Date().toISOString()
          const date2 = new Date().toLocaleDateString()
          const formData = {
            job_number: this.jobNumber,
            phase_id: this.phaseId,
            user_id: this.userId,
            phase_date: date,
            phase_notes: '*auto mobile* ' + this.phaseName,
            is_complete: this.isComplete,
            is_dashboard: this.isDashboard,
            create_date: date,
            crew: '',
            date_notes: '',
            extra_field: this.jobDetail,
            extra_field_user: this.userDetail, 
            extra_field_date: date2
          }
          axios
            .post('/api/v1/project/job-phase/', formData)
            .then(response => {
              console.log(JSON.stringify(response))
            })
            .catch(error => {
              console.log(JSON.stringify(error))
            })
            this.$emit('close-dialog', this.phaseId)
            //this.$router.push('/projects')      
            this.$emit('confirm-dryin-clicked')
        }
    }
}
</script>