import axios from "axios";

const state = {
    projects: [],
    projectDetails: [],
    projectPage: 1,
    totalPages: null,

    phases: [],
    projectPhases: [],
    projectPhasesList: [],

    currentUser: [],
};

const getters = {
    allProjects: (state) => state.projects,
    allProjectDetails: (state) => state.projectDetails,
    projectCurrentPage: (state) => state.projectPage,
    getTotalPages: (state) => state.totalPages,
    
    allPhases: (state) => state.phases,
    allProjectPhases: (state) => state.projectPhases,
    allProjectPhasesList: (state) => {
        const phasesList = []
        const currentPhases = []
        // allProjectPhases.forEach((item) => {
        //   currentPhases.push(item.phase_id.id)
        // })


        state.allProjectPhases.forEach((item) => {
          currentPhases.push(item.phase_id.id)
          
        })
        // console.log(currentPhases)

        state.allPhases.forEach((item) => {
          // console.log(item.id)
            if (currentPhases.indexOf(item.id) != -1){
              phasesList.push({
              "id": item.id, 
              "disabled": true, 
              "phase_name": item.phase_name, 
              "is_quick_complete": item.is_quick_complete
              })
            } else {
              phasesList.push({
              "id": item.id, 
              "disabled": false, 
              "phase_name": item.phase_name, 
              "is_quick_complete": item.is_quick_complete
              })
            }
        })
        return(phasesList)
    },

    allUserInfo: (state) => state.currentUser,

};

const actions = {
    async fetchProjects({ commit }, args=[]) {
      // actions will only accept one parameter, so args is an array that needs to be unpacked
      // For example:
      //   if args=[], then we get default values (search='', showCompleted=false)
      //   if args=['123'], then search='123', showCompleted=false
      //   if args=['123',true], then search='123', showCompleted=true
      const [search='', showCompleted=false] = args

      // Start with the base URL
      let url = `/api/v1/project/job/?page=${state.projectPage}`
      // Add the search and show_complete parameters, if they exist
      if (search)        { url += `&search=${search}` }
      if (showCompleted) { url += '&show_complete=1'  }

      const response = await axios.get(url);  
      commit('setProjects', response.data.results);
      commit('setTotalPages', Math.ceil(response.data.count / 10))
      // console.log(response)
    },
    async fetchProjectDetails({ commit }, projectId) {
        state.projectDetails = []
        const response = await axios.get(`/api/v1/project/job/${projectId}/`);
        commit('setProjectDetails', response.data);
        // console.log(response.data)
    },
    fetchProjectPage({ commit }, projectPage) {
        commit('setProjectPage', projectPage)
    },


    async fetchPhases({ commit }) {
        const response = await axios.get('/api/v1/project/phases/');
          commit('setPhases', response.data)
    },
    async fetchProjectPhases({ commit }, jobNumber) {
        const response = await axios.get(`api/v1/project/job/${jobNumber}/`);
        commit('setProjectPhases', response.data.phases)
        // console.log(response.data.phases)
    },
    async postProjectPhase({ commit }, formData) {
        const response = await axios.post(`api/v1/project/job-phase/`, formData);
        commit('newProjectPhase', response.data)
    },
    async fetchProjectPhasesList({ commit }) {
        const phasesList = []
        const currentPhases = []
        // allProjectPhases.forEach((item) => {
        //   currentPhases.push(item.phase_id.id)
        // })


        state.allProjectPhases.forEach((item) => {
          currentPhases.push(item.phase_id.id)
          
        })
        // console.log(currentPhases)

        state.allPhases.forEach((item) => {
          // console.log(item.id)
            if (currentPhases.indexOf(item.id) != -1){
              phasesList.push({
              "id": item.id, 
              "disabled": true, 
              "phase_name": item.phase_name, 
              "is_quick_complete": item.is_quick_complete
              })
            } else {
              phasesList.push({
              "id": item.id, 
              "disabled": false, 
              "phase_name": item.phase_name, 
              "is_quick_complete": item.is_quick_complete
              })
            }
        })
        console.log(phasesList)
        commit('setProjectPhasesList', phasesList)
    },


    async fetchCurrentUser({ commit }) {
        const response = await axios.get('/api/v1/user/me/');
        commit('setCurrentUser', response.data)
        // console.log(response)
    }
};

const mutations = {
    setProjects: (state, projects) => (state.projects = projects),
    setProjectDetails: (state, projectDetails) => (state.projectDetails = projectDetails),
    setProjectPage: (state, projectPage) => (state.projectPage = projectPage),
    setTotalPages: (state, totalPages) => (state.totalPages = totalPages),

    setPhases: (state, phases) => (state.phases = phases),
    setProjectPhases: (state, projectPhases) => (state.projectPhases = projectPhases),
    newProjectPhase: (state, projectPhase) => (state.projectPhases.push(projectPhase)),
    setProjectPhasesList: (state, projectPhasesList) => (state.projectPhasesList = projectPhasesList),
   
    setCurrentUser: (state, currentUser) => (state.currentUser = currentUser),
    };

export default {
    state,
    getters,
    actions,
    mutations
}