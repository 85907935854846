<template>

<v-card class="pa-3">
  <v-form
   ref="form"
    @submit.prevent="submitForm"
  >
    <v-row class="px-3">
    <v-text-field
      v-model="email"
      label="Email"
      prepend-icon="mdi-account-circle"
    ></v-text-field>
    </v-row>

    <v-row class="px-3" >
    <v-text-field
      v-model="password"
      type="password"
      label="Password"
      prepend-icon="mdi-lock"
    ></v-text-field>
    </v-row>

    <v-row class="px-3" align="center" justify="center">
    <v-btn
      color="success"
      class="mr-4"
      type="submit" 
    >
      Log In
    </v-btn>
    </v-row>


    <div class="pt-3">
        <v-card 
            elevation="2">
            <v-alert
                dense
                type="error"
                v-if="errors.length"
            >
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </v-alert>
        </v-card>
    </div>
  </v-form>
</v-card>
</template>

<script>
import axios from 'axios'
export default {
    name: 'LogIn',
    data () {
        return {
            valid: true,
            email: '',
            password: '',
            errors: [],
        }
    },
    
    methods: {
        validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
        submitForm(e) {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")

            const formData = {
                email: this.email,
                password: this.password
            }

            axios
                .post("/api/v1/auth/token/login/", formData)
                .then(response => {
                    const token = response.data.auth_token

                    this.$store.commit('setToken', token)

                    axios.defaults.headers.common["Authorization"] = "Token " + token
                    
                    localStorage.setItem("token", token)

                    this.$router.push('/projects')
                })
                .catch(error => {
                if (error.response) {
                    for (const property in error.response.data) {
                        this.errors.push(`${property}: ${error.response.data[property]}`)
                    }
                    console.log(JSON.stringify(error.response.data))
                } else if (error.message) {
                    console.log(JSON.stringify(error.message))
                } else {
                    console.log(JSON.stringify(error))
                }
                })
        }
    }
}
</script>