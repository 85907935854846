<template>

  <v-menu>

        <template v-slot:activator="{ on }">
        
          <v-btn
            class="pa-1 ma-1"
            text
            small
            color="gray"
            v-on="on"
          >
            {{ date_formatted }}
          </v-btn>

        </template>

        <v-date-picker
          v-model="picker_date"
          no-title
          scrollable
          @input="menu = false"
          @change="change_event"
        >
        </v-date-picker>

      </v-menu>

</template>

<script>
export default {
  props: ['date','info'],
  data () {
    return {
      shown_date: this.date || '-',
      picker_date: this.date || new Date().toISOString().slice(0,10),
      menu: false,
    }
  },
  methods: {
    change_event () {
      this.shown_date = this.picker_date
      this.$emit('change', [this.picker_date, this.info])
    },
  },
  computed: {
    date_formatted () { return this.shown_date.slice(0,10) }
  }
}
</script>