<template>
<div>
    <v-radio-group v-model="is_blow_off" row>
        Did you blow the roof,patio,lower decks & cricket area off? <br>
        Sopleteo el techo, el patio, los nivels de abajo y los grillos?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_hip_stacks" row>
        Were all hip stacks wired and installed properly? <br>
        Instalaste las estacas y las amarraste con alabre adecuadamente?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_valley" row>
        Did you cut valley back, broom trash off and piece valley together? <br>
        Cortaste los valles y limpiaste los pedazos de teja antes de cerrarlo?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_nail_code_4" row>
        Did you nail to code (5/12 and below 3 tiles in, out, up and down)? <br>
        Clavaste la teja segun el codigo 5/12 y menos adentro, afuera, arriba y en la orilla ?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_nail_code_5" row>
        Did you nail to code (5/12 to 7.99/12--3 tiles in, out, up and down plus every other row)? <br>
        Clavaste la teja segun el codigo de 5/12 a 7.99/12 - 3 tejas adentro, afuera, arriba y en la orilla?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_nail_code_6" row>
        Did you nail to code (8/12 and adove) and (3/12 and below) every tile? <br>
        Clavaste la teja segun el codigo (8/12 y arriba) y (3/12 y abajo) todas las tejas?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_secure_adhesive" row>
        Were the first 3 cut pieces secured with adhesive and the rest with mastic/plastic cement? <br>
        Se aseguraron las tres piezas cortadas de la orilla con pegamento y los demas con cemento plastico?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_tile_overhang" row>
        Does the tile overhang a min. of 1 inch past the eve but does not exceed 2 inchs? <br>
        Se dejo la tejo asomando 1 pulgada sobre la horilla pero no mas de 2 pulgadas?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_courses_overlap" row>
        Do all tile and rake courses have a 3 inch head lap/overlap? <br>
        Estan todas las tejas encimadas por lo menos 3 pulgadas?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_hips_ridge_secured" row>
        Hips and ridge trim laid straight and secured properly(nails covered with plastic cement)? <br>
        Dejaste hips y ridges derechos y pegados y pegados con cemento plastico?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_no_broken_tile" row>
        No broken tiles left on roof? <br>
        se dejo teja quebrada sobre el techo?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_damage_dry_in" row>
        Was there any damage to dry in and did you seal and waterproof? <br>
        Se repararo cualquier dano en el papel para no haber goteras?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_jack_mud_painted" row>
        Are all jacks and mud balls (where required) painted? <br>
        Se pintaron los jacks, t-tops y el cemento?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_trash_neat" row>
        Is the tile trash in one neat pile in front of the house? <br>
        Dejaste un solo monton de basura alfrente de la casa?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_trash_pickup" row>
        Did you pick up the trash around the house? <br>
        Se recojio la basura de alrededor de la casa?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_hip_closure_run" row>
        Do you run the hip closure to the bottom of the hip stack? <br>
        Corrio el cierre de la cadera abajo de la estaca?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_birdstop_over" row>
        Was the birdstop/tile riser cut out over the valley and J-pan metal to allow for water flow? <br>
        Se corto el bird-stop/tile riser sobre el valle y la charola para que corra el agua bien?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_birdstop_tucked" row>
        Is the birdstop/tile riser tucked into the rib of the first course tile? <br>
        Esta el bird-stop/tile riser metido en la teja?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_shinners" row>
        Are there any shinners if so did you take care of them? <br>
        Se cortaron o se quitaron los clavos que salieron por debajo?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_edge_metal_ran" row>
        If required was the edge metal ran back to front? <br>
        Si es necesario se puso el metal dela horilla de atras hacia enfrente?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_edge_metal_pieces" row>
        No metal installed smaller than 3 ft? <br>
        Se usaron pedasos de metal mas chicos de 3 pies?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_edge_metal_lap" row>
        Did all edge metal have a 3-inch lap? <br>
        Se ensimaron las horillas del metal por lo menos 3 pulgadas?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_patio_roof_good" row>
        Was the patio roof still in good condition? <br>
        Se dejo el techo sobre los patios en buenas condiciones?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_patio_cleaned" row>
        Did you clean the patio, lower decks and cricket areas off prior to laying tile? <br>
        Limpio el patio, pisos de abajo y areas de grillos antes de tender la teja?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_ridge_exposed_nails" row>
        When looking over the ridge line, are there any exposed nails? <br>
        Cuando se ve la horilla no se van clavos salidos?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_ridge_hip_lap" row>
        Do all hip and ridge tiles have a 2 inch head lap? <br>
        Se encimaron las caderas y ridges 2 pulgadas?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_ridge_hip_closure_installed" row>
        Was all hip, ridge and headwall closure installed? <br>
        Se instalaron los cierres contra la pared de las caderas y los ridges?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_cut_marks" row>
        No extensive cut marks left on the tile around hips and valleys? <br>
        Hay marcas de cortadas en la teja sobre loa valles, caderas y penetraciones?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_alum_flashings_installed" row>
        Are all the aluminum flashings installed and secured with plastic cement? <br>
        Se instalo el aluminio y se aseguro con el uso de cemento plastico?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_pipe_flashings_tucked" row>
        Are all pipe flashings tucked inside the pipes properly? <br>
        Se instalaron las tapaderas en los tubos/pipas adecuadamente?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-radio-group v-model="is_dye_lots_blend" row>
        Did all dye lots blend together? <br>
        Se mezclaron los lotes de teja para verse bien?
        <v-radio label="YES/SI" value="true"></v-radio>
        <v-radio label="NO" value="false"></v-radio>
        <v-radio label="N/A" value="null"></v-radio>
        <hr>
    </v-radio-group>
    <v-textarea
        solo
        name="input-7-4"
        label="NOTES/NOTAS"
        v-model="notes"
    ></v-textarea>
    
        
        
        
        
        <v-btn
        color="red darken-1"
        text
        @click="cancelDialog"
    >
        CANCEL
        </v-btn>
        <v-btn
            color="green darken-1"
            text
            @click="closeModal"
        >
            CONFIRM
        </v-btn>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'TileLayForm',
    emits: ['close-dialog'],
    props: [
        'userId',
        'jobNumber',
        'jobDetail',
        'userDetail'
    ],
    data() {
        return {
        is_blow_off: null,
        is_hip_stacks: null,
        is_valley: null,
        is_nail_code_4: null,
        is_nail_code_5: null,
        is_nail_code_6: null,
        is_secure_adhesive: null,
        is_tile_overhang: null,
        is_eve_line_straight: null,
        is_courses_overlap: null,
        is_hips_ridge_secured: null,
        is_no_broken_tile: null,
        is_damage_dry_in: null,
        is_jack_mud_painted: null,
        is_trash_neat: null,
        is_trash_pickup: null,
        is_hip_closure_run: null,
        is_birdstop_over: null,
        is_birdstop_tucked: null,
        is_shinners: null,
        is_edge_metal_ran: null,
        is_edge_metal_pieces: null,
        is_edge_metal_lap: null,
        is_patio_roof_good: null,
        is_patio_cleaned: null,
        is_ridge_exposed_nails: null,
        is_ridge_hip_lap: null,
        is_ridge_hip_closure_installed: null,
        is_cut_marks: null,
        is_alum_flashings_installed: null,
        is_pipe_flashings_tucked: null,
        is_dye_lots_blend: null,
        notes: null,
        install_date: null,
        qa_date: null,
        super_user_id: null,
        email_content: null,
        create_date: null
        }
    },
    methods:{
        cancelDialog() {
            this.$emit('close-dialog')
        },
        closeModal() {
          const date = new Date().toISOString()
          const date2 = new Date().toLocaleDateString()
          const formData = {
            job_number: this.jobNumber,
            user_id: this.userId,
            is_blow_off: this.is_blow_off,
            is_hip_stacks: this.is_hip_stacks,
            is_valley: this.is_valley,
            is_nail_code_4: this.is_nail_code_4,
            is_nail_code_5: this.is_nail_code_5,
            is_nail_code_6: this.is_nail_code_6,
            is_secure_adhesive: this.is_secure_adhesive,
            is_tile_overhang: this.is_tile_overhang,
            is_eve_line_straight: this.is_eve_line_straight,
            is_courses_overlap: this.is_courses_overlap,
            is_hips_ridge_secured: this.is_hips_ridge_secured,
            is_no_broken_tile: this.is_no_broken_tile,
            is_damage_dry_in: this.is_damage_dry_in,
            is_jack_mud_painted: this.is_jack_mud_painted,
            is_trash_neat: this.is_trash_neat,
            is_trash_pickup: this.is_trash_pickup,
            is_hip_closure_run: this.is_hip_closure_run,
            is_birdstop_over: this.is_birdstop_over,
            is_birdstop_tucked: this.is_birdstop_tucked,
            is_shinners: this.is_shinners,
            is_edge_metal_ran: this.is_edge_metal_ran,
            is_edge_metal_pieces: this.is_edge_metal_pieces,
            is_edge_metal_lap: this.is_edge_metal_lap,
            is_patio_roof_good: this.is_patio_roof_good,
            is_patio_cleaned: this.is_patio_cleaned,
            is_ridge_exposed_nails: this.is_ridge_exposed_nails,
            is_ridge_hip_lap: this.is_ridge_hip_lap,
            is_ridge_hip_closure_installed: this.is_ridge_hip_closure_installed,
            is_cut_marks: this.is_cut_marks,
            is_alum_flashings_installed: this.is_alum_flashings_installed,
            is_pipe_flashings_tucked: this.is_pipe_flashings_tucked,
            is_dye_lots_blend: this.is_dye_lots_blend,
            notes: this.notes,
            install_date: this.install_date,
            qa_date: this.qa_date,
            super_user_id: this.super_user_id,
            email_content: this.email_content,
            create_date: date
          }
          console.log(formData)
          axios
            .post('/api/v1/project/form-tile-lay/', formData)
            .then(response => {
              console.log(JSON.stringify(response))
            })
            .catch(error => {
              console.log(JSON.stringify(error))
            })
         const formDataPhase = {
            job_number: this.jobNumber,
            phase_id: 8,
            user_id: this.userId,
            phase_date: date,
            phase_notes: '*auto mobile* Dashboard Lay Tile Schedule',
            is_complete: false,
            is_dashboard: true,
            create_date: date,
            crew: '',
            date_notes: date2+'-'+this.userDetail.username,
            extra_field: this.jobDetail,
            extra_field_user: this.userDetail, 
            extra_field_date: date2
          }
          axios
            .post('/api/v1/project/job-phase/', formDataPhase)
            .then(response => {
              console.log(JSON.stringify(response))
            })
            .catch(error => {
              console.log(JSON.stringify(error))
            })
            // console.log(formData)

            // this.$emit('close-dialog', 8)
            this.$router.push('/projects')
            
        }
    }
}
</script>