import Vue from 'vue'
import VueRouter from 'vue-router'

import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/MyAccount.vue'
import Projects from '../views/Projects.vue'
import Project from '../views/Project.vue'
import ProjectStatus from '../views/ProjectStatus.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
        name: 'projects'
    }
},
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    meta: {
      
    }
  },
    {
    path: '/project/:id',
    name: 'Project',
    component: Project,
    meta: {
      
    }
  },
  {
  path: '/project/status/:id',
  name: 'ProjectStatus',
  component: ProjectStatus,
  meta: {
    
  }
}, 
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta:{

    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router 
