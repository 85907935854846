<template>

<v-card class="pa-3">
  <v-form
   ref="form"
    @submit.prevent="submitForm"
  >
    <v-text-field
      v-model="username"
      label="Username"
    ></v-text-field>

    <v-text-field
      v-model="password"
      label="Password"
    ></v-text-field>

    <v-btn
      color="success"
      class="mr-4"
      type="submit"
    >
      Sign Up
    </v-btn>

    <div class="pt-3">
        <v-card 
            elevation="2">
            <v-alert
                dense
                type="error"
                v-if="errors.length"
            >
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </v-alert>
        </v-card>
    </div>
  </v-form>

</v-card>

</template>

<script>
import axios from 'axios'
export default {
    name: 'SignUp',
    data () {
        return {
            username: '',
            password: '',
            errors: [],
        }
    },
    methods: {
        submitForm(e) {
            const formData = {
                username: this.username,
                password: this.password,
            }

            axios
                .post("/api/v1/users/", formData)
                .then(response => {
                    console.log(response)

                    this.$router.push('/log-in')
            })
            .catch(error => {
                if (error.response) {
                    for (const property in error.response.data) {
                        this.errors.push(`${property}: ${error.response.data[property]}`)
                    }
                    console.log(JSON.stringify(error.response.data))
                } else if (error.message) {
                    console.log(JSON.stringify(error.message))
                } else {
                    console.log(JSON.stringify(error))
                }
            })
        }   
    }
}
</script>