<template>
    <div>
        <div >
            <div>
                <h1>{{ project.description }}</h1>
            </div>


            
            <v-simple-table dense>
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        Phase Id
                    </th>
                    <th class="text-left">
                       Date
                    </th>
                    <th class="text-left">
                        Notes
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="phase in project.jobnum"
                    :key="phase.id" 
                    >
                        <td>{{ phase.phase_id }}</td>
                        <td>{{ phase.phase_date }}</td>
                        <td>{{ phase.phase_notes }}</td>
                    </tr>

                </tbody>
                </template>
                </v-simple-table>



        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Project',
    data() {
        return {
            phases: [],
            project: [],
            sideMenu: true,
            filters: true,
            search: '',
            pagination: {
            descending: true,
            page: 1,
            itemsPerPage:10,
            rowsPerPage: 10, // -1 for All",
            }
        }
    },
    mounted() {
        this.getProject(),
        this.getPhases()

    },
    methods: {
        getPhases() {
            axios
                .get('/api/v1/phase/')
                .then(response => {
                    this.phases = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getProject() {
            const projectID = this.$route.params.id

            axios   
                .get(`/api/v1/project/${projectID}/`)
                .then(response => {
                    this.project = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        }
    }
}
</script>